import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue";
const _hoisted_1 = ["action"];
const _hoisted_2 = {
    type: "submit",
    class: "btn btn-icon search-overlay-form-submit"
};
const _hoisted_3 = { class: "visually-hidden" };
const _hoisted_4 = ["href"];
const _hoisted_5 = {
    key: 1,
    class: "text-truncate"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_inline_svg = _resolveComponent("inline-svg");
    const _component_el_autocomplete = _resolveComponent("el-autocomplete");
    return (_openBlock(), _createElementBlock("form", {
        ref: "root",
        class: "search-overlay-form",
        action: _ctx.formAction
    }, [
        _createElementVNode("button", _hoisted_2, [
            _createVNode(_component_inline_svg, {
                src: require('@dw_boilerplate/Partials/Icons/Search.svg')
            }, null, 8, ["src"]),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.label), 1)
        ]),
        _createVNode(_component_el_autocomplete, {
            type: "search",
            name: "tx_solr[q]",
            id: "tx_solr[q]",
            label: _ctx.label,
            placeholder: _ctx.label,
            modelValue: _ctx.state,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.state) = $event)),
            "fetch-suggestions": _ctx.solr.getSuggestions(_ctx.serviceUrl),
            "trigger-on-focus": false
        }, {
            default: _withCtx(({ item }) => [
                (item.link)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        href: item.link,
                        class: "el-autocomplete-suggestion__link text-truncate"
                    }, _toDisplayString(item.title), 9, _hoisted_4))
                    : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.noResults), 1))
            ]),
            _: 1
        }, 8, ["label", "placeholder", "modelValue", "fetch-suggestions"])
    ], 8, _hoisted_1));
}
