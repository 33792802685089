import { Directive } from 'vue';

const DownloadListFilterDirective:Directive = {
    mounted(el:HTMLSelectElement) {
        el.addEventListener('change', () => {
            const list = el.closest('.ce-download-list');

            if (list) {
                const items = list.querySelectorAll<HTMLElement>('.ce-download-list-item');

                items.forEach((item) => {
                    if (item.dataset.year === el.value || el.value === "all") {
                        item.classList.remove('d-none');
                    } else {
                        item.classList.add('d-none');
                    }
                })
            }
        });
    },
}

export default DownloadListFilterDirective;
