import { ref } from 'vue';
import axios from "axios";
export default class Solr {
    constructor() {
        this.isSearchOpen = ref(false);
    }
    getSuggestions(serviceUrl, noResults) {
        return (query, callback) => {
            axios.get(serviceUrl, {
                params: {
                    'tx_solr[queryString]': query,
                    'tx_solr[callback]': '' // SOLR needs this callback to work
                }
            }).then((response) => {
                let results;
                let data;
                if (response.status !== 200 || response.data.status == false) {
                    return callback([{ link: '' }]);
                }
                data = response.data;
                results = data.documents;
                callback(results);
            });
        };
    }
}
