import { defineComponent, ref, inject, watch } from 'vue';
import { ElAutocomplete } from "element-plus";
import InlineSvg from 'vue-inline-svg';
export default defineComponent({
    components: {
        ElAutocomplete,
        InlineSvg
    },
    props: {
        serviceUrl: {
            type: String,
            required: true
        },
        formAction: {
            type: String,
            required: true
        },
        label: {
            type: String,
        },
        noResultsText: {
            type: String,
            default: ''
        }
    },
    setup(props) {
        const state = ref(''), label = ref(props.label), root = ref(), noResults = ref(props.noResultsText), serviceUrl = ref(props.serviceUrl), formAction = ref(props.formAction), solr = inject('solr');
        if (!solr) {
            return;
        }
        watch(solr.isSearchOpen, (newValue) => {
            var _a;
            if (newValue) {
                const searchInput = (_a = root.value) === null || _a === void 0 ? void 0 : _a.querySelector('.el-input__inner');
                window.setTimeout(function () {
                    searchInput === null || searchInput === void 0 ? void 0 : searchInput.focus();
                }, 500);
            }
        });
        return {
            solr, state, serviceUrl, formAction, label, noResults, root
        };
    },
});
