
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import PerfectScrollbar from 'perfect-scrollbar';
import { debounce } from "lodash-es";

const SCROLL_DELTA = 50;

export default defineComponent({
    setup() {
        let isHidden = ref(false);
        let isSticky = ref(false);
        let lastScrollPosition = window.scrollY;

        window.addEventListener('resize', debounce(() => {
            if (window.innerWidth < 1440) {
                document.body.classList.remove("main-sub-nav-opened");
            }
            if (window.innerWidth >= 1440) {
                document.body.classList.remove("mobile-nav-opened");
            }
        }, 250))


        window.addEventListener('scroll', () => {
            if (window.scrollY > 50) {
                isSticky.value = true
                if (Math.abs(window.scrollY - lastScrollPosition) >= SCROLL_DELTA) {
                    isHidden.value = window.scrollY >= lastScrollPosition;
                    lastScrollPosition = window.scrollY;
                }
            } else {
                isSticky.value = false;
                isHidden.value = false;
            }
        });
        onMounted(() => {
            document.addEventListener("keydown", ({key}) => {
                if (key === "Escape") {
                    document.querySelector('.main-nav-item.js-opened')?.classList.remove('js-opened');
                    document.body.classList.remove("main-sub-nav-opened");
                }
            });
            document.querySelectorAll('.main-sub-nav-list').forEach((scrollableArea) => {
                scrollableArea.addEventListener('ps-scroll-y', () => {
                    if (scrollableArea.scrollTop == 0) {
                        scrollableArea.classList.remove('main-sub-nav-list--top-shadow');
                    } else {
                        scrollableArea.classList.add('main-sub-nav-list--top-shadow');
                    }
                    if ((scrollableArea.scrollTop + scrollableArea.clientHeight + 5) >= scrollableArea.scrollHeight) {
                        scrollableArea.classList.remove('main-sub-nav-list--bottom-shadow');
                    } else {
                        scrollableArea.classList.add('main-sub-nav-list--bottom-shadow');
                    }
                })
            })
        })

        onUnmounted(() => {
            document.querySelectorAll('.main-sub-nav-list').forEach((scrollableArea) => {
                scrollableArea.removeEventListener('ps-scroll-y', () => {
                });
            })
        })


        const toggleMobileNav = () => {
            let mobileNav = document.querySelector('.mobile-nav');
            if (mobileNav) {
                document.body.classList.toggle('mobile-nav-opened');
                if (document.body.classList.contains('mobile-nav-opened')) { // opening mobile nav
                    const activeNavItems: Array<HTMLElement> = [];
                    Array.from<HTMLAnchorElement>(mobileNav.querySelectorAll('a.active')).forEach((link) => {
                        const activeNavItem = link.parentElement;
                        if (activeNavItem && activeNavItem.classList.contains('sub')) {
                            activeNavItems.push(activeNavItem);
                        }
                    });
                    activeNavItems.forEach((item) => {
                        item.classList.add('js-opened');
                    });
                } else { // closing mobile nav
                    document.body.classList.remove('main-sub-nav-opened');
                    Array.from<HTMLAnchorElement>(mobileNav.querySelectorAll('.js-opened')).forEach((item) => {
                        item.classList.remove('js-opened');
                    });
                }
            }
        };

        const toggleMainNav = (event: Event, closeNav: boolean = false) => {
            let windowSize = window.innerWidth;
            if ((windowSize < 1440 && (event.type === 'mouseenter' || event.type === 'mouseleave')) ||
                (windowSize >= 1440 && !document.querySelector("body")?.classList.contains("site--sempermed") && !document.querySelector("body")?.classList.contains("site--hoses"))) {
                return false
            } else {
                const navItem = event.target ? (<HTMLInputElement>event.target).parentElement : null;
                if (navItem) {
                    const parent = navItem.parentElement;
                    if (closeNav) {
                        document.body.classList.remove("main-sub-nav-opened");
                        document.querySelector('.main-nav-item.js-opened')?.classList.remove('js-opened');
                    } else {
                        const activeItem = parent?.querySelector(".nav-item.js-opened");
                        if (activeItem !== navItem) {
                            activeItem?.classList.remove('js-opened');
                            navItem.classList.add('js-opened');
                        }
                    }
                }

                if (document.querySelector(".main-nav-item.js-opened")) {
                    document.body.classList.add("main-sub-nav-opened");
                    document.querySelectorAll('.main-sub-nav-list').forEach((scrollableArea) => {
                        if (scrollableArea.clientHeight < scrollableArea.scrollHeight) {
                            new PerfectScrollbar(scrollableArea, {
                                suppressScrollX: true,
                                scrollYMarginOffset: 5
                            });
                        }
                        if ((scrollableArea.scrollTop + scrollableArea.clientHeight + 5) >= scrollableArea.scrollHeight) {
                            scrollableArea.classList.remove('main-sub-nav-list--bottom-shadow');
                        } else {
                            scrollableArea.classList.add('main-sub-nav-list--bottom-shadow');
                        }
                    })
                } else {
                    document.body.classList.remove("main-sub-nav-opened");
                }
            }
        }

        const toggleSubNav = (event: Event, parentId: string) => {
            const navItem = event.target ? (<HTMLInputElement>event.target).parentElement : null;
            const currentItem = document.querySelector(".nav-item.js-opened.current");

            if (parentId) {
                if (currentItem) {
                    currentItem?.classList.remove('js-opened', 'current');
                    document.querySelector(`#${parentId}`)?.classList.add('current');
                }
            } else {
                if (navItem) {
                    if (navItem.classList.contains('sub')) {
                        if (currentItem !== navItem) {
                            currentItem?.classList.remove('current');
                            navItem.classList.add('js-opened', 'current');
                        }
                    }
                }
            }
        }

        const toggleAreaNav = (event: Event) => {
            let mobileAreaNav = document.querySelector('.mobile-area-nav');
            if (mobileAreaNav) {
                event.preventDefault();
                document.body.classList.toggle('mobile-area-nav-opened');
            }
        };

        return {
            isHidden, toggleMainNav, toggleSubNav, toggleMobileNav, toggleAreaNav, isSticky
        };
    },
})
