
import { defineComponent, inject, onMounted, ref } from 'vue';
import { Swiper } from 'swiper/vue';
import SwiperCore, { Navigation } from 'swiper';
import InlineSvg from 'vue-inline-svg';

SwiperCore.use([Navigation]);

export default defineComponent({
    props: {
        uid: {
            type: String,
            required: true
        },
        options: {
            type: Object,
            default: {
                speed: 500,
                threshold: 10,
                spaceBetween: 16,
                slidesPerView: 1.75,
                centerInsufficientSlides: true,
                watchOverflow: true,
                navigation: {
                    prevEl: '.teasers-slider-nav--prev',
                    nextEl: '.teasers-slider-nav--next',
                },
                breakpoints: {
                    576: {
                        slidesPerView: 2.25,
                    },
                    992: {
                        spaceBetween: 30,
                        slidesPerView: 3,
                    },
                }
            }
        }
    },
    setup(props) {
        let swiperOptions = ref(props.options);
        const uid = ref(props.uid);
        swiperOptions.value.navigation = {
            prevEl: `#teasers-slider-nav--prev-${uid.value}`,
            nextEl: `#teasers-slider-nav--next-${uid.value}`
        };
        const lazyLoad: any = inject('lazyLoad');
        onMounted(() => {
            lazyLoad.update();
        });
        return {swiperOptions, uid};
    },
    components: {
        swiper: Swiper,
        InlineSvg
    },
});
