import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("header", {
        class: _normalizeClass({ 'header--hidden': _ctx.isHidden, 'header--sticky': _ctx.isSticky })
    }, [
        _renderSlot(_ctx.$slots, "default", {
            toggleMobileNav: _ctx.toggleMobileNav,
            toggleSubNav: _ctx.toggleSubNav,
            toggleMainNav: _ctx.toggleMainNav,
            toggleAreaNav: _ctx.toggleAreaNav
        })
    ], 2));
}
